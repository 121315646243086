import React from "react";
import { graphql } from "gatsby";
import CapabilitiesTemplate from "src/templates/capabilities";

export const query = graphql`
	query CapabilitiesQuery {
		page: sanityCapabilities(_id: { regex: "/(drafts.|)capabilities/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;

const CapabilitiesPage = (props: any) => {
	const { location } = props;
	const content = props.data.page.content;
	return <CapabilitiesTemplate location={location} data={content} />;
};

export default CapabilitiesPage;
